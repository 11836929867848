import {ACTIONS} from '../actions';

const localUserStatus = (state = {}, action) => {
    switch (action.type) {
        case ACTIONS.LOCAL_USER_STATUS_READY:
            return action.status;
        default:
            return state;
    }
};



export default localUserStatus;