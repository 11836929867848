import { ACTIONS } from '../actions'

const contracts = (state = {}, action) => {
  switch (action.type) {
    case ACTIONS.LOG_DEPLOY_SUCCESS:
      let newState = { ...state }
      newState[action.name] = action.address
      return newState
    case ACTIONS.RESET_DEPLOY_LOG:
      return {}
    default:
      return state
  }
}

export default contracts
