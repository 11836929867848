import React from 'react'
import TitleHeader from '../layout/TitleHeader'
import { connect } from 'react-redux'
import { updateLocalUser, downloadCertificate } from '../../actions/localUserStatus'
import { Link } from 'react-router-dom'
import { urlify } from '../../lib/helpers'

function completionStatus(userStatus, pageTitle) {
  if (!userStatus.status.hasOwnProperty(pageTitle) || Object.keys(userStatus.status[pageTitle]).length === 0) {
    return "incomplete"
  }
  var complete = true
  for (const execId in userStatus.status[pageTitle]) {
    if (!userStatus.status[pageTitle][execId]) {
      complete = false
      break
    }
  }
  
  if (complete) {
    return "complete"
  }
  return "incomplete"
}

class TableOfContents extends React.Component {
  constructor(props) {
    super(props)
    this.state = { newUser: "" }
    this.handleChange = this.handleChange.bind(this)
    this.handleUpdateLocalUser = this.handleUpdateLocalUser.bind(this)
    this.handleDownloadCertificate = this.handleDownloadCertificate.bind(this)
  }

  render() {
    return (
      <section className="hero">
        <TitleHeader title={'Table of Contents'} />
        <div className="hero-body content">
          <div>
            <div className={'mb2'}>
              <div className="columns">
                <div className={'column mt3'}>
                  <h1 className="title">Table of Contents</h1>
                  <div>
                    <div className={'icon inline-block toc-user'}>
                      <i className="fas fa-university" />
                    </div>
                    <div className={'inline-block has-text-weight-bold toc-user'}>
                      {this.props.localUserStatus.user}
                    </div>
                    <div className={'inline-block'}>
                      <input className={'input user-input'}
                        type={'text'}
                        placeholder={this.props.localUserStatus.user}
                        id="newUser"
                        value={this.state.newUser}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div onClick={this.handleUpdateLocalUser} className={'inline-block button toc-user'}>
                      <i className="fas fa-sync" />
                    </div>
                    <div onClick={this.handleDownloadCertificate} className={'inline-block button toc-user'}>
                      <i className="fas fa-download" />
                    </div>
                  </div>
                  {this.getTOC()}
                </div>
                <div className={'column mt3'} />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  getTOC() {
    const startTOC = 'intro'
    return <div className={'toc'}>{this.getTOCFromCategoryRecursive(startTOC, true)}</div>
  }

  getTOCFromCategoryRecursive(categoryName, first = false, startIdx = 0) {
    let content = []
    if (!categoryName) return content
    let currentCategory = this.props.categories[categoryName]
    if (currentCategory) {
      content = currentCategory.map((itm, idx) => this.getTOCLinkEntry(categoryName, itm, idx, first, startIdx))
      let lastitem = currentCategory[currentCategory.length - 1]
      if (lastitem && currentCategory.length > 0 && lastitem.next && lastitem.next.length > 0) {
        const nextCatName = urlify(this.getNextCategoryFromUrl(lastitem.next[0]).toLowerCase())
        const subitems = this.getTOCFromCategoryRecursive(nextCatName, false, currentCategory.length + startIdx)
        content.push(...subitems)
      }
    }
    return content
  }

  getNextCategoryFromUrl(nextUrl) {
    const page = this.props.pages.find(itm => itm.url === nextUrl)
    if (!page) return null
    if (!page.categories || page.categories.length === 0) return null
    return page.categories[0]
  }

  getTOCLinkEntry(categoryName, page, index, first, startIdx) {
    const isOverview = page.url.endsWith('/')
    const title = isOverview ? 'Overview' : page.title
    const url = `/pages/${categoryName}` + (!isOverview ? `/${urlify(title)}` : '')

    if (index === 0) {
      return (
        <div
          key={startIdx + index}
          className={`toc-item toc-category has-text-white has-text-weight-bold has-flex-grow has-background-info ${
            first ? 'toc-first' : ''
          }`}
        >
          <Link to={url} className={`text`}>
            {page.title}
          </Link>
        </div>
      )
    }

    const status = completionStatus(this.props.localUserStatus, page.title)
    return (
      <div key={startIdx + index}>
        <div className={'icon'}>
            <i className={"fas fa-check-circle " + status} />
        </div>
        <div className={'toc-item has-text-weight-bold has-flex-grow inline-block'}>
          <Link to={url} className={`text`}>
            {page.title}
          </Link>
        </div>
      </div>
    )
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value })
  }

  handleUpdateLocalUser() {
    this.props.updateLocalUser(this.state.newUser)
  }

  handleDownloadCertificate() {
    this.props.downloadCertificate(this.props.localUserStatus)
  }
}

const mapStateToProps = state => {
  return {
    pages: state.pages,
    categories: state.categories,
    localUserStatus: state.localUserStatus,
  }
}

const mapDispatchToProps = dispatch => ({
  updateLocalUser: newUser => dispatch(updateLocalUser(newUser)),
  downloadCertificate: userStatus => dispatch(downloadCertificate(userStatus)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableOfContents)
