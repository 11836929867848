const ACTIONS = {
  SETUP_WEB3_ACCOUNT: 'SETUP_WEB3_ACCOUNT',
  SETUP_WEB3_ACCOUNT_SUCCESS: 'SETUP_WEB3_ACCOUNT_SUCCESS',
  SETUP_WEB3_ACCOUNT_FAILURE: 'SETUP_WEB3_ACCOUNT_FAILURE',
}

export const WEB3_ACTIONS = ACTIONS

export const setupWeb3Account = () => ({
  type: ACTIONS.SETUP_WEB3_ACCOUNT,
})

export const setupWeb3AccountSuccess = () => ({
  type: ACTIONS.SETUP_WEB3_ACCOUNT_SUCCESS,
})

export const setupWeb3AccountFailure = () => ({
  type: ACTIONS.SETUP_WEB3_ACCOUNT_FAILURE,
})
