import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { ACTIONS } from '../actions'
import store from '../store'
import linker from 'solc/linker'
import { postUrl } from '../lib/helpers'
import { web3, walletAddress } from '../lib/web3'

import { setupWeb3AccountFailure, setupWeb3AccountSuccess } from '../actions/web3'
import {
  deployFailure,
  deploySuccess,
  deployUpdate,
  logDeploySuccess,
  testContractsFailure,
  testContractsSuccess,
  testContractsUpdate,
} from '../actions/exercise'
import { completeExercise } from '../actions/localUserStatus'
import { setTestExerciseUpdate, testExerciseDeployFailure, testExerciseDeploySuccess } from '../actions/testing'

export default [
  takeLatest(ACTIONS.SETUP_WEB3_ACCOUNT, workerSetupAccount),
  takeEvery(ACTIONS.DEPLOY_CONTRACTS, workerDeployContracts),
  takeEvery(ACTIONS.TEST_CONTRACTS, workerPerformTests),
  takeEvery(ACTIONS.TEST_EXERCISE_DEPLOY, workerTestExerciseDeploy),
]

function* workerSetupAccount() {
  try {
    const r = yield call(postUrl, `/api/users/topup/${walletAddress}`)
    if (r.status !== 200) {
      yield put(setupWeb3AccountFailure())
      return
    }
    const balance = new web3.utils.BN(r.data.toString())
    if (balance.lt(new web3.utils.BN(web3.utils.toWei('0.2', 'ether')))) yield put(setupWeb3AccountFailure())
    else yield put(setupWeb3AccountSuccess())
  } catch (e) {
    console.log(e)
    yield put(setupWeb3AccountFailure())
  }
}

function deploy(contract) {
  const abi = contract.interface
  const bc = '0x' + contract.bytecode
  const mcontract = new web3.eth.Contract(JSON.parse(abi))

  return new Promise(async resolve => {
    mcontract
      .deploy({
        data: bc,
      })
      .estimateGas({
        from: walletAddress,
      })
      .then(async gasAmount => {
        mcontract
          .deploy({
            data: bc,
          })
          .send({
            from: walletAddress,
            gas: gasAmount,
            // chainId: '1558358647262',
            // gasPrice: 1,
          })
          .then(dContract => {
            resolve(dContract)
          })
      })
  })
}

function* workerDeployContracts(action) {
  try {
    const addresses = []
    let index = 0

    // Deploy all contracts
    for (let name of Object.keys(action.contracts)) {
      name = name.substring(1)
      const msg = `Deploying ${name}'\t${index++}/${Object.keys(action.contracts).length}`
      yield put(deployUpdate(action.codeId, msg))
      try {
        const deployedCode = yield call(deploy, action.contracts[':' + name])
        addresses.push(deployedCode.options.address)
        yield put(logDeploySuccess(name, deployedCode.options.address))
      } catch (error) {
        return yield put(deployFailure(action.codeId, `Contract ${name}: ${error}`))
      }
    }

    yield put(
      deploySuccess(
        action.codeId,
        addresses,
        `Successfully deployed ${Object.keys(action.contracts).length} Contracts.`,
      ),
    )
  } catch (error) {
    console.log('error in workerDeployContracts', error)
    yield put(deployFailure(action.codeId, error))
  }
}

function* workerPerformTests(action) {
  try {
    const validation = action.validation // JSON.parse(action.validation);
    let tests = true
    let errors = ''
    for (let index = 0; index < validation.length; index++) {
      const test = validation[index]
      const cTest = new web3.eth.Contract(test.abi, test.address)
      const r = yield call(performTests, action.codeId, cTest, test.abi, action.addresses)
      tests = tests && r.result
      errors += r.errors.join('\n')
    }
    if (tests) {
      console.log("test success", action.codeId)
      yield put(testContractsSuccess(action.codeId))
      yield put(completeExercise(action.codeId))
    } else {
      yield put(testContractsFailure(action.codeId, errors))
    }
  } catch (error) {
    console.log('Error in workerPerformTests', error)
    yield put(testContractsFailure(action.codeId, error))
  }
}

/**
 * Listen for new events of a test contract and resolve when all tests have passed or if one has failed
 * @param codeId - code id
 * @param {{TestEvent: function}} contract - Test contracts
 * @param addresses - Addresses of the deployed contracts
 * @returns {Promise<{result: boolean, errors: Array<string>}>} - True if the tests have passed
 */
function performTests(codeId, contract, abi, addresses) {
  let result = true
  const errors = []
  let resultReceived = 0
  let testCases = abi.filter(c => {return c.type === 'function'}).length

  return new Promise(async (resolve, reject) => {
    try {
      // Listen for transaction results
      contract.events.TestEvent().on('data', async event => {
        let tx = await web3.eth.getTransaction(event.transactionHash)
        if (tx.from === walletAddress) {
          resultReceived++
          // Only propagate testContractUpdate action, if errors is still empty. Success-callback can
          // arrive later than error due to race condition and accidentally overwrite status on ui.
          if (errors.length === 0) {
            // ignore event if resultReceived is larger than no. of tests. Can happen, when user executes the exercise
            // twice in a row and two listeners listen for the same results. Only new listener should trigger an update
            if (resultReceived <= testCases) {
              store.dispatch(testContractsUpdate(codeId, `Test ${resultReceived}/${testCases}`))
            }
          }
          // only care event triggered by self
          result = result && event.returnValues.result
          if (!event.returnValues.result) {
            errors.push(event.returnValues.message)
          }
          // Resolve only after all test results
          if (resultReceived === testCases) {
            resolve({ result: result, errors: errors })
          }
        }
      })

      // Perform a transaction for every function to test
      let fTests = abi
        .filter(c => {
          return c.type === 'function'
        })
        .sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      let nonce = await web3.eth.getTransactionCount(walletAddress)
      for (let iTest = 0; iTest < fTests.length; iTest++, nonce++) {
        store.dispatch(testContractsUpdate(codeId, `Test ${0}/${testCases}`))
        const test = fTests[iTest]
        let txParams = {
          gasPrice: 0,
          from: walletAddress,
          // chainId: '1558358647262',
          nonce: nonce,
        }
        if (abi.filter(t => t.name === test.name)[0].payable === true) {
          txParams.value = web3.utils.toWei('0.002', 'ether')
        }
        try {
          txParams.gas = 2000000
          contract.methods[test.name](addresses)
            .send(txParams)
            .on('error', err => {
              errors.push(err)
              console.log(`${test.name}: ${err.message}`)
              return resolve({ result: false, errors: errors })
            })
        } catch (err) {
          errors.push(err)
          resolve({ result: false, errors: errors })
        }
      }

      // If contract.abi has only TestEvent or nothing
      if (testCases < 1) {
        resolve({ result: true, errors: [] })
      }
    } catch (err) {
      reject(err)
    }
  })
}

async function deployTests(compiledTests, assertLibraryAddress) {
  return new Promise(async resolve => {
    const toDeploy = Object.keys(compiledTests).filter(key => key.startsWith('test.sol'))
    const tests = []

    for (const key of toDeploy) {
      // Link test with the already deployed assert library
      compiledTests[key].bytecode = linker.linkBytecode(compiledTests[key].bytecode, {
        'Assert.sol:Assert': assertLibraryAddress,
      })
      // Deploy the test
      const address = await deploy(compiledTests[key])
      tests.push({ address: address, abi: JSON.parse(compiledTests[key].interface) })
    }
    resolve(tests)
  })
}

function* workerTestExerciseDeploy(action) {
  try {
    yield put(setTestExerciseUpdate('Deploying Assert Library'))
    const deployedAssertLib = yield call(deploy, action.assert['Assert.sol:Assert'])
    const assertLibraryAddress = deployedAssertLib.address
    console.log('Assert Library sucessfully deployed.')

    yield put(setTestExerciseUpdate('Deploying Test Contracts'))
    const deployedTests = yield call(deployTests, action.validation, assertLibraryAddress)
    console.log('Test Contracts sucessfully deployed.')

    const deployedTestsObject = deployedTests.map(test => {
      return { address: test.address.address, abi: test.abi }
    })

    const exerciseAddresses = []
    let index = 0

    yield put(setTestExerciseUpdate('Deploying Exercise'))
    // Deploy all contracts
    for (let name of Object.keys(action.exercise)) {
      name = name.substring(1)
      const msg = `Deploying ${name}'\t${index++}/${Object.keys(action.exercise).length}`
      yield put(setTestExerciseUpdate(msg))
      const deployedCode = yield call(deploy, action.exercise[':' + name])
      exerciseAddresses.push(deployedCode.address)
    }

    console.log('Successfully deployed exercise contracts')
    yield put(testExerciseDeploySuccess(exerciseAddresses, deployedTestsObject))
  } catch (e) {
    yield put(testExerciseDeployFailure(e.message || e))
  }
}
