import React from 'react';
import TitleHeader from "../layout/TitleHeader";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {Prompt} from "react-router";
import {saveProfile} from "../../actions/user";

class ProfileEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nameChanged: false,
            submitted: false,
            nameValue: '',
            initialized: false
        };
        this.onChangeDisplayName = this.onChangeDisplayName.bind(this);
        this.saveProfileChanges = this.saveProfileChanges.bind(this);
    }

    render() {
        return (
            <section className="hero">
                <TitleHeader/>
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <h1 className="title">User Profile</h1>
                        <div className='content'>
                            {this.getProfileEditContent()}
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    componentDidMount() {
        this.setFormValues();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setFormValues();
    }

    setFormValues() {
        if (this.props.user && !this.state.initialized) {
            this.setState({
                nameValue: this.props.user.displayName, initialized: true
            });
        }
    }

    getProfileEditContent() {
        if (this.state.submitted) {
            if (this.props.user.saving) {
                return <div>
                    <span className='icon loading has-text-info'><i className='fas fa-spinner fa-spin'/></span>
                    <span>Saving changes...</span>
                </div>
            } else {
                return <Redirect to='/login'/>
            }
        } else {
            return this.getUserProfileEditForm()
        }
    }

    saveProfileChanges(e) {
        e.preventDefault();
        this.setState({nameChanged: false,submitted: true });
        this.props.saveProfile(e.target.displayName.value);
    }

    onChangeDisplayName(event) {
        this.setState({nameValue: event.target.value});
    }

    hasUnsavedChanges() {
        return this.state.nameValue !== this.props.user.displayName;
    }

    getUserProfileEditForm() {
        const unsavedChanges = this.hasUnsavedChanges();
        return <>
            <form onSubmit={this.saveProfileChanges}>
                <Prompt when={unsavedChanges}
                        message={`You have unsaved changes. Are you sure you want to leave this page?`}/>
                <div className="field is-horizontal">
                    <div className="field-label">
                        <label className="label">Display Name</label>
                    </div>
                    <div className="field-body">
                        <div className="field has-text-left">
                            <p className='control has-icons-left'>
                                <span className='icon is-small is-left'><i className='fas fa-user'/></span>
                                <input id='displayName' name='displayName' type='text' className='input'
                                       value={this.state.nameValue} onChange={this.onChangeDisplayName}/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="field is-horizontal">
                    <div className="field-label"><label className="label"><Link to='/profile'>Cancel</Link></label>
                    </div>
                    <div className="field-body">
                        <div className="field has-text-left">
                            <button type='submit' className={`button is-info `} disabled={!unsavedChanges}>Save</button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    }
}

const mapStateToProps = state => {
    return {
        user: state.appState.user,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveProfile: displayName => dispatch(saveProfile(displayName)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
