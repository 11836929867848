import React from 'react'
import Navigation from './layout/Navigation'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from './Home'
import Login from './user/Login'
import Logout from './user/Logout'
import Pages from './page/Pages'
import Page from './page/Page'
import { loadPages } from '../actions/pages'
import { loadLocalUserStatus } from '../actions/localUserStatus'
import { connect } from 'react-redux'
import Footer from './layout/Footer'
import Profile from './user/Profile'
import ProfileEdit from './user/ProfileEdit'
import Playground from './Playground'
import SearchPage from './search/SearchPage'
import { Page404 } from './page/Page404'
import Testing from './testing/Testing'
import ScrollToTop from './layout/ScrollToTop'
import TableOfContents from './toc/TOC'
import { PageView, initGA } from './tracking'

class App extends React.Component {
  componentDidMount() {
    this.props.loadLocalUserStatus()
    this.props.loadPages()
    // this.props.loadLocalUserStatus()
    initGA('UA-149619638-1')
    PageView()
  }

  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <div>
            <Navigation />
            <Switch>
              <Route path={'/demo'} component={Playground} />
              <Route exact path={'/search'} component={SearchPage} />
              <Route exact path={'/toc'} component={TableOfContents} />
              <Route exact path={'/'} component={Home} />
              <Route path={'/login/:app?'} component={Login} />
              <Route path={'/logout'} component={Logout} />
              <Route exact path={'/profile'} component={Profile} />
              <Route exact path={'/profile/edit'} component={ProfileEdit} />
              <Route path={'/pages/:category/:page'} component={Page} />
              <Redirect from={'/start'} to={'/pages/Intro'} />
              <Route path={'/testing'} component={Testing} />
              <Route exact path={'/pages/:category'} component={Pages} />
              <Route path={'/'} component={Page404} />
            </Switch>
            <Footer />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadLocalUserStatus: () => dispatch(loadLocalUserStatus()),
    loadPages: () => dispatch(loadPages()),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(App)
