import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './components/App'
import { Provider } from 'react-redux'
import store from './store'
import { web3Init, web3 } from './lib/web3'

web3Init(store)
window.store = store
window.workbenchWeb3 = web3

ReactDOM.render((
  <Provider store={store}>
    <App />
  </Provider>
), document.getElementById('root'))
