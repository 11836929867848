import {combineReducers} from "redux";

import pagesReducers from './pages';
import solidityReducers from './solidity';
import exercises from './exercises';
import user from './user';

const pages = combineReducers(pagesReducers);
const solidity = combineReducers(solidityReducers);

export default combineReducers({pages, solidity, exercises, user});
