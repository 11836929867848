import React from 'react'
import { Link } from 'react-router-dom'
import workerImg from '../assets/img/worker.svg'
import contentCreatorImg from '../assets/img/undraw_content_creator.svg'
import interactiveDiscussionImg from '../assets/img/undraw_interactive_discussion.svg'
import codingImg from '../assets/img/undraw_coding.svg'
import smartContractImg from '../assets/img/undraw_operating_system_4lr6.svg'
import bookImg from '../assets/img/book.svg'
import contributionImg from '../assets/img/contribution.svg'

import hslu from '../assets/img/partners/hslu_lab_logo.jpg'
import imperial from '../assets/img/partners/imperial_logo.svg'
import liquidity from '../assets/img/partners/liquidity_network_logo.svg'

const partners = [
  {
    name: 'Imperial College',
    url: 'https://imperial.ac.uk',
    logo: imperial,
  },
  {
    name: 'Hochschule Luzern - Informatik',
    url: 'https://www.hslu.ch/en/lucerne-school-of-information-technology/',
    logo: hslu,
  },
  {
    name: 'Liquidity Network',
    url: 'https://liquidity.network',
    logo: liquidity,
  },
]

export default function Home() {
  return (
    <div>
      <section className="hero is-light is-fullheight hero-header-component">
        <div className="hero-body has-text-white">
          <div className="container has-text-centered">
            <img src={workerImg} className={'image landing-page-image'} alt={''} />
            <h1 className={'title is-1 has-text-white is-cursive is-extra-bold'}>Forge Your Blockchain Skills</h1>
            <h2 className={'is-2 has-text-white'}>
              A light primer of Blockchain + an interactive online IDE to learn Solidity smart contracts.
            </h2>
            <div className={'content'} />
            <div className="buttons" style={{ display: 'block' }}>
              <Link className="button is-light is-big" to={'/start'}>
                <span className="icon">
                  <i className="fas fa-graduation-cap" />
                </span>
                <span>Start!</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <div className={'container mt3'}>
        <section className="section has-background-color-darker">
          <div className="columns is-vcentered">
            <div className="column">
              <img
                className={'image image-narrow-box border-shadow'}
                src={bookImg}
                height={'100%'}
                margin={0}
                padding={0}
                alt={''}
              />
            </div>
            <div className="column feature-box">
              <h1 className={'title is-1'}>Education at core</h1>
              <aside className="menu">
                <p className={'text is-2'}>
                  Blockchainworkbench.com is a blockchain online learning platform. It aims to provide basic knowledge
                  of blockchains and interactive solidity programming exercises to beginners.
                </p>
              </aside>
            </div>
          </div>
        </section>
      </div>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title has-text-centered is-1'}>Who can benefit</h1>
          <ul className={'columns mt3'}>
            <li className={'column'}>
              <figure className={'image is-128x128 is-centered'}>
                <img src={codingImg} alt={'Coding'} />
              </figure>
              <h2 className={'is-2 has-text-centered mb10'}>Programmers</h2>
              <p className={'text'}>
                Build up your blockchain skills with interactive programming exercises and get certified automatically
                at no fees.
              </p>
            </li>
            <li className={'column'}>
              <figure className={'image is-128x128 is-centered'}>
                <img src={interactiveDiscussionImg} alt={'Discussion'} />
              </figure>
              <h2 className={'is-2 has-text-centered mb10'}>Universities</h2>
              <p className={'text'}>
                Courses are currated by blockchain scholars, built by and for Universities. Provides hands-on exercises
                to your students.
              </p>
            </li>
            <li className={'column'}>
              <figure className={'image is-128x128 is-centered'}>
                <img src={contentCreatorImg} alt={'Creator'} />
              </figure>
              <h2 className={'is-2 has-text-centered mb10'}>Companies</h2>
              <p className={'text'}>
                As a blockchain company, you can contribute with educational exercises.
              </p>
            </li>
          </ul>
        </div>
      </section>

      <div className={'container mt3'}>
        <section className="section has-background-color-darker">
          <div className="columns is-vcentered">
            <div className="column feature-box">
              <h1 className={'title is-1'}>Contribute</h1>
              <aside className="menu">
                <p className={'text is-2'} style={{ marginBottom: '1em' }}>
                  The project is made by researchers and engineers. Everything being open source, you can contribute
                  directly to it by providing documentation, translating it, or directly coding new features.
                </p>
                <div className={'buttons'}>
                  <a
                    href={'arthur@gervais.cc'}
                    className={'button is-medium is-dark'}
                    target={'_blank'}
                    rel={'noopener noreferrer'}
                  >
                    <span className={'icon'}>
                      <i className={'fas fa-address-card'} />
                    </span>
                    <span>Contact us</span>
                  </a>
                </div>
              </aside>
            </div>
            <div className="column">
              <img
                className={'image image-narrow-box border-shadow'}
                src={contributionImg}
                height={'100%'}
                margin={0}
                padding={0}
                alt={''}
              />
            </div>
          </div>
        </section>
      </div>

      <section className={'mt3 section partners'}>
        <h1 className={'title has-text-centered is-2'}>
          Supported by world-leading universities and blockchain startups
        </h1>
        <div className={'container is-flex'}>
          {partners.map((partner, index) => (
            <a key={index} href={partner.url} className={'border-shadow'}>
              <figure key={index} className={`image is-full-height ${index % 2 === 0 ? 'is-flex' : ''}`}>
                <img src={partner.logo} alt={partner.name} />
              </figure>
            </a>
          ))}
        </div>
      </section>

      <div className={'container mt3'} style={{ marginBottom: '4em' }}>
        <section className="section has-background-color-darker">
          <div className="columns is-vcentered">
            <div className="column feature-box">
              <h1 className={'title'}>Start Learning</h1>
              <aside className="menu">
                <ul className="menu-list">
                  <li>
                    <Link to={'/pages/Intro'} className="text is-2">
                      Blockchain basics
                    </Link>
                  </li>
                  <li>
                    <Link to={'/pages/Layout_of_a_Solidity_Source_File'} className="text is-2 is-active">
                      Smart contracts on Ethereum
                    </Link>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="column" style={{ padding: '1rem' }}>
              <img className={'image image-box border-shadow'} src={smartContractImg} height={200} alt={''} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
