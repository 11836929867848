import React from 'react'
import { Link } from 'react-router-dom'
import brandLogo from '../../assets/img/brand-logo-white-small.png'

export default function Footer() {
  return (
    <footer className={'footer has-background-info level'}>
      <Link to={'/'}>
        <img className={'image'} src={brandLogo} alt={'Logo'} />
      </Link>
      <div className={'level has-text-centered is-3'}>
        <div className="level-item ml2">
          <a
            href={'https://sites.hslu.ch/blockchainlab/'}
            className={'has-text-white'}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >About
          </a>
        </div>
      </div>
    </footer>
  )
}
