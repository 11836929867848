import Web3 from 'web3'
import { setupWeb3Account } from '../actions/web3'

export var web3
export var walletAddress

export function web3Init(store) {
  web3 = new Web3(new Web3.providers.WebsocketProvider(process.env.REACT_APP_BLOCKCHAIN_PROVIDER), null, {
    transactionConfirmationBlocks: 1,
  })
  const wallet = web3.eth.accounts.wallet.create(1)
  walletAddress = wallet[0].address
  // walletAddress = wallet.accounts[0].address
  store.dispatch(setupWeb3Account())
}
