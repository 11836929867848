const ACTIONS = {
  LOAD_LOCAL_USER_STATUS: 'LOAD_LOCAL_USER_STATUS',
  LOCAL_USER_STATUS_READY: 'LOCAL_USER_STATUS_READY',
  UPDATE_LOCAL_USER: 'UPDATE_LOCAL_USER',
  RECORD_EXERCISE: 'RECORD_EXERCISE',
  COMPLETE_EXERCISE: 'COMPLETE_EXERCISE',
  DOWNLOAD_CERTIFICATE: 'DOWNLOAD_CERTIFICATE',
}

export const LOCAL_USER_STATUS_ACTIONS = ACTIONS

export const loadLocalUserStatus = () => ({
  type: ACTIONS.LOAD_LOCAL_USER_STATUS,
})

export const localUserStatusReady = (status) => ({
  type: ACTIONS.LOCAL_USER_STATUS_READY,
  status: status,
})

export const updateLocalUser = user => ({
  type: ACTIONS.UPDATE_LOCAL_USER,
  user: user,
})

export const recordExercise = (pageTitle, execId) => ({
  type: ACTIONS.RECORD_EXERCISE,
  pageTitle: pageTitle,
  execId: execId
})

export const completeExercise = (execId) => ({
  type: ACTIONS.COMPLETE_EXERCISE,
  execId: execId,
})

export const downloadCertificate = (userStatus) => ({
  type: ACTIONS.DOWNLOAD_CERTIFICATE,
  userStatus: userStatus,
})