import { put, takeLatest, takeEvery } from 'redux-saga/effects'
import { ACTIONS } from '../actions'
import { localUserStatusReady } from '../actions/localUserStatus'
import { save } from 'save-file'
import { web3 } from '../lib/web3'

export default [
  takeLatest(ACTIONS.LOAD_LOCAL_USER_STATUS, workerLoadLocalUserStatus),
  takeLatest(ACTIONS.UPDATE_LOCAL_USER, workerUpdateLocalUser),
  takeEvery(ACTIONS.RECORD_EXERCISE, workerRecordExercise),
  takeEvery(ACTIONS.COMPLETE_EXERCISE, workerCompleteExercise),
  takeLatest(ACTIONS.DOWNLOAD_CERTIFICATE, workerDownloadCertificate),
]

function* workerLoadLocalUserStatus(action) {
  var userStatus = JSON.parse(localStorage.getItem('blockchainworkbenchUserStatus'))
  if (!userStatus) {
    userStatus = Object.assign({}, {user: "N/A", status: {}})
    localStorage.setItem('blockchainworkbenchUserStatus', JSON.stringify(userStatus))
  }
  yield put(localUserStatusReady(userStatus))
}

function* workerUpdateLocalUser(action) {
  const newUser = action.user
  var userStatus = JSON.parse(localStorage.getItem('blockchainworkbenchUserStatus'))

  if (!userStatus) {
    userStatus = Object.assign({}, {user: newUser, status: {}})
    localStorage.setItem('blockchainworkbenchUserStatus', JSON.stringify(userStatus))
    yield put(localUserStatusReady(userStatus))
  } else if (userStatus.user !== newUser) {
    userStatus.user = newUser
    for (const pageTitle in userStatus.status) {
      for (const execId in userStatus.status[pageTitle]) {
        userStatus.status[pageTitle][execId] = false
      }
    }
    localStorage.setItem('blockchainworkbenchUserStatus', JSON.stringify(userStatus))
    yield put(localUserStatusReady(userStatus))
  }
}

function* workerRecordExercise(action) {
  const pageTitle = action.pageTitle
  const execId = action.execId

  var ready = false

  var userStatus = JSON.parse(localStorage.getItem('blockchainworkbenchUserStatus'))
  var status = userStatus.status
  if (!status.hasOwnProperty(pageTitle)) {
    status[pageTitle] = {}
    ready = true
  }
  if (!status[pageTitle].hasOwnProperty(execId)) {
    status[pageTitle][execId] = false
    ready = true
  }
  if (ready) {
    userStatus.status = status
    localStorage.setItem('blockchainworkbenchUserStatus', JSON.stringify(userStatus))
    yield put(localUserStatusReady(userStatus))
  }
}

function* workerCompleteExercise(action) {
  const completedExecId = action.execId
  var userStatus = JSON.parse(localStorage.getItem('blockchainworkbenchUserStatus'))
  var ready = false

  for (const pageTitle in userStatus.status) {
    for (const execId in userStatus.status[pageTitle]) {
      if (String(completedExecId) === String(execId) && !userStatus.status[pageTitle][execId]) {
        console.log("set status")
        userStatus.status[pageTitle][execId] = true
        ready = true
      }
    }
  }
  if (ready) {
    localStorage.setItem('blockchainworkbenchUserStatus', JSON.stringify(userStatus))
    yield put(localUserStatusReady(userStatus))
  }
}

function workerDownloadCertificate(action) {
  const userStatusString = JSON.stringify(action.userStatus)
  const enc = new TextEncoder()
  const encodedUserStatus = enc.encode(userStatusString)
  const wallet = web3.eth.accounts.wallet.create(1)
  const signed = web3.eth.accounts.sign(Buffer.from(encodedUserStatus).toString('hex'), wallet[0].privateKey)
  var blob = new Blob([Buffer.from(JSON.stringify(signed)).toString('hex')],
                { type: "text/plain;charset=utf-8" })
  save(blob, action.userStatus.user)
}