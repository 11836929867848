import React from 'react'
import { connect } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class ContractDeployInfoElement extends React.Component {
  constructor(props) {
    super(props)
    this.state = { copied: false, adr: null }
  }

  render() {
    if (this.props.content && this.props.content.length > 0) {
      if (this.props.contracts) {
        let adr = this.props.contracts[this.props.content[0]]
        if (adr) {
          return (
            <p className={'has-text-success'}>
              Your contract {this.props.content} is deployed at address
              <CopyToClipboard text={adr} onCopy={() => this.setState({ copied: true, adr: adr })}>
                <strong className={'pointer'}> {adr} </strong>
              </CopyToClipboard>
              {this.state.copied && adr === this.state.adr ? (
                <span>
                  <i>Copied!</i>
                </span>
              ) : (
                '(click to copy to clipboard).'
              )}
            </p>
          )
        } else {
          return <p>{this.props.content[0]} is not yet deployed.</p>
        }
      } else {
        return <p>{this.props.content[0]} is not yet deployed.</p>
      }
    }
    return <p>Invalid Configuration</p>
  }
}

const mapStateToProps = state => {
  return { contracts: state.contracts }
}

export default connect(mapStateToProps)(ContractDeployInfoElement)
